export default function Timeline() {
	return (
		<div className="component component-timeline">
			<div className="container">
				<h1>Ablauf</h1>
				<hr />
				<img
					src="/img/apero.svg"
					style={{ width: '30px', marginBottom: '15px' }}
					alt="Karte"
				/>
				<div className="d-flex justify-content-center">
					<p>Der Apéro beginnt um 16.00 Uhr und dauert ca. 3 Stunden.</p>
				</div>
			</div>
		</div>
	)
}

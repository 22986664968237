export default function Location() {
	const link: string =
		'https://www.google.com/maps/place/Schloss+Freudenfels/@47.5952485,8.8903777,9.45z/data=!4m8!3m7!1s0x0:0xe26c634b0aea3299!5m2!4m1!1i2!8m2!3d47.638746!4d8.8900522'

	return (
		<div className="component component-location">
			<div className="container">
				<h1>Location</h1>
				<p>
					Der Hochzeitsapéro findet im{' '}
					<a href="https://www.schloss-freudenfels.ch/de/">
						Schloss Freudenfels am Bodensee
					</a>{' '}
					statt.
				</p>
				<a rel="noreferrer" href={link} target="_blank">
					<img
						style={{
							objectFit: 'cover',
							width: '100%',
							height: '200px',
							marginBottom: '30px',
							marginTop: '20px',
						}}
						src="/img/schloss_freudenfels.png"
						alt="Schloss Freudenfels"
					/>
				</a>
				<hr />
				<h2>Google Maps</h2>
				<a rel="noreferrer" href={link} target="_blank">
					<img
						style={{
							objectFit: 'cover',

							position: 'relative',
							width: '100%',
							height: '200px',
							marginBottom: '30px',
						}}
						src="/img/google_maps.png"
						alt="Karte"
					/>
				</a>
				<hr />
				<h2>Adresse</h2>
				<p>
					Schloss Freudenfels
					<br />
					Schlossweg
					<br />
					CH-8264 Eschenz
				</p>
			</div>
		</div>
	)
}

export default function Informations() {
	const link: string =
		'https://www.google.com/maps/place/Schloss+Freudenfels/@47.5952485,8.8903777,9.45z/data=!4m8!3m7!1s0x0:0xe26c634b0aea3299!5m2!4m1!1i2!8m2!3d47.638746!4d8.8900522'

	return (
		<div className="component component-informations">
			<div className="container">
				<h1>Informationen</h1>
				<hr />

				<h2>Anreise</h2>

				<h3>Mit dem Auto:</h3>

				<p>
					Das Schloss Freudenfels befindet sich an wunderschöner, idyllischer Lage
					oberhalb dem Untersee und ca. 7 km von Stein am Rhein entfernt.
					<br />
					Siehe auch{' '}
					<a href={link} target="_blank" rel="noreferrer">
						Google Maps
					</a>
					.
				</p>

				<p>
					Bitte gebt uns bei der Anmeldung an, ob ihr mit dem Auto anreist, da wir dem
					Schloss die Anzahl benötigten Parkplätze im Vorfeld mitzuteilen haben.
				</p>
				<br />
				<h3>Mit dem ÖV:</h3>
				<p>
					Mit dem Zug reist ihr am besten mit dem Schnellzug bis nach Frauenfeld, Stein am
					Rhein oder direkt mit dem Regio nach Eschenz und von da aus weiter mit dem Taxi,
					oder zu Fuss (20 Minuten Fussweg).
					<br />
					(Es sind leider keine Busse vorhanden, die bis zum Schloss fahren.)
				</p>
				<hr />

				<h2>Kleidung</h2>
				<p>festlich</p>
				<hr />

				<h2>Geschenke</h2>
				<p>
					Unsere Hochzeit lässt uns auf Wolke Sieben schweben,
					<br />
					doch unser Konto hat danach nicht mehr viel herzugeben.
					<br />
					Deswegen macht euch darüber keine Gedanken,
					<br />
					wir freuen uns am allermeisten über ein paar Franken.
					<br />
				</p>
				<div
					style={{
						color: '#b17f4c',
						fontSize: '2em',
						marginTop: '5px',
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="currentColor"
						className="bi bi-heart-fill"
						viewBox="0 0 16 16"
					>
						<path
							fillRule="evenodd"
							d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
						/>
					</svg>
				</div>
			</div>
		</div>
	)
}

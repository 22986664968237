import styles from '../../styles/Start.module.css'

export default function Start() {
	function renderHeart(): JSX.Element {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="16"
				style={{ marginRight: '8px', marginTop: '-3px' }}
				fill="currentColor"
				className="bi bi-heart"
				viewBox="0 0 16 16"
			>
				<path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
			</svg>
		)
	}

	return (
		<div className="component-start">
			<div className={styles.imgRingContainer}>
				<img className={styles.imgRing} src="/img/ring.svg" alt="Karte" />
			</div>
			<div className={styles.line}></div>

			<div className={styles.date}>24 | 06 | 23</div>
			<div className={styles.names}>Carmen</div>
			<div className={styles.and}>&</div>
			<div className={styles.names}>Nathanael</div>
			<img
				className={styles.imgPairContainer}
				src="/img/background.jpg"
				alt="Carmen und Nathanael"
			/>
			<div className={styles.slogan + ' container'} style={{fontSize: '30px'}}>
				Einladung zum Hochzeitsapéro
			</div>
			<div className="container" style={{ marginTop: '8px' }}>
				<p style={{ color: 'grey' }}>
					Wir heiraten – nach 9 Jahren Beziehung geben wir uns am SA, 24.06.2023 das
					Ja-Wort. Auf dieses besondere Lebensereignis wollen wir gerne mit euch bei
					unserem Hochzeitsapéro anstossen.
					<br />
					<a href="#page-registration">Hier gehts zur Anmeldung</a>
				</p>
			</div>
		</div>
	)
}

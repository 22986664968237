import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Informations from '../components/Informations'
import Location from '../components/Location'
import Navigation from '../components/Type2/Navigation'
import Registration from '../components/Registration'
import Start from '../components/Start'
import Timeline from '../components/Timeline'
import { GuestApi } from '../interfaces/GuestApi'
import { RegistrationApi } from '../interfaces/RegistrationApi'

interface Props {
	guests: GuestApi[]
	registration: RegistrationApi
	setRegistration: (input: RegistrationApi) => void
	alreadyRegistered: boolean
	allGood: boolean
	setAllGood: (input: boolean) => void
	status: number | null
	setStatus: (input: number | null) => void
	putRegistration: () => Promise<void>
}

export default function InvitationType1(props: Props) {
	return (
		<>
			<Navigation />
			<div id="page-start">
				<Start />
			</div>
			<div id="page-location" className="navigation">
				<Location />
			</div>
			<div id="page-timeline" className="navigation">
				<Timeline />
			</div>
			<div id="page-informations" className="navigation">
				<Informations />
			</div>
			<div id="page-registration" className="navigation">
				<Registration
					registration={props.registration}
					setRegistration={props.setRegistration}
					alreadyRegistered={props.alreadyRegistered}
					guests={props.guests}
					putRegistration={props.putRegistration}
					allGood={props.allGood}
					setAllGood={props.setAllGood}
					setStatus={props.setStatus}
					status={props.status}
				/>
			</div>
			<div id="page-contact" className="navigation">
				<Contact />
			</div>
			<Footer />
		</>
	)
}

import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

import * as apiGetGuests from '../functions/api/getGuests'
import * as apiGetRegistration from '../functions/api/getRegistration'
import * as apiPutRegistration from '../functions/api/putRegistration'
import InvitationType1 from '../pages/InvitationType1'
import InvitationType2 from '../pages/InvitationType2'
import Loading from '../components/Loading'
import NotFound from '../components/NotFound'
import { GuestApi } from '../interfaces/GuestApi'
import { RegistrationApi } from '../interfaces/RegistrationApi'
import { defaultRegistrationApi } from '../interfaces/RegistrationApi'

export default function Invitation() {
	const [searchParams] = useSearchParams()

	function getToken(): string {
		const token: string | null = searchParams.get('t')
		if (token) {
			return token
		}
		return ''
	}

	const [guests, setGuests] = useState<GuestApi[]>([])
	const [registration, setRegistration] = useState<RegistrationApi>(defaultRegistrationApi())

	const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false)
	const [allGood, setAllGood] = useState<boolean>(false)
	const [status, setStatus] = useState<number | null>(null)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize(): Promise<void> {
		setLoading(true)
		await getGuests()
		await getRegistration()
		setLoading(false)
	}

	async function getGuests(): Promise<void> {
		if (getToken()) {
			const guests: GuestApi[] = await apiGetGuests.apiCall(getToken())
			setGuests(guests)
		}
	}

	async function getRegistration(): Promise<void> {
		if (getToken()) {
			const registration: RegistrationApi = await apiGetRegistration.apiCall(getToken())
			if (registration) {
				setRegistration(registration)
				setAlreadyRegistered(true)
			}
		}
	}

	async function putRegistration(): Promise<void> {
		if (getToken()) {
			try {
				const response: apiPutRegistration.Response = await apiPutRegistration.apiCall(
					getToken(),
					registration ?? defaultRegistrationApi()
				)
				//setGuests(response.guest)
				setStatus(response.status)
			} catch (e) {
				setStatus(400)
				console.error(e)
			}
		}
	}

	if (loading) {
		return <Loading />
	}
	if (guests.length === 0) {
		return <NotFound />
	}
	if (guests[0]) {
		if (guests[0].GuestType === 1) {
			return (
				<InvitationType1
					registration={registration}
					setRegistration={setRegistration}
					alreadyRegistered={alreadyRegistered}
					guests={guests}
					putRegistration={putRegistration}
					allGood={allGood}
					setAllGood={setAllGood}
					setStatus={setStatus}
					status={status}
				/>
			)
		}
		if (guests[0].GuestType === 2) {
			return (
				<InvitationType2
					registration={registration}
					setRegistration={setRegistration}
					alreadyRegistered={alreadyRegistered}
					guests={guests}
					putRegistration={putRegistration}
					allGood={allGood}
					setAllGood={setAllGood}
					setStatus={setStatus}
					status={status}
				/>
			)
		}
	}
	return (
		<>
			<h1>Loading</h1>
		</>
	)
}
